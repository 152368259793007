import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import ChineseLayout from "../../../components/ChineseLayout"
// import Inactivity from "../../../components/Inactivity"

/* Farms page, uses the Video > Rich Text > Stepped Navigation structure */

class Farms extends React.Component {
  componentDidMount() {
    // Inactivity()
  }
  render() {
    return(
      <ChineseLayout>
        <Helmet>
          <title>Meet MJN - Visit One Of Our Farms - Milk</title>
        </Helmet>
        <main role="main" className="content-main">

          <section className="hero-area -general -video">
            <div className="video-player-wrapper">
              <div className="youtube-player-wrapper">
                <iframe className="youtube-player" id="youtube-video" title="video" width="540" height="304" src="https://www.youtube.com/embed/DH0rhJrfC-4?controls=0&modestbranding=1&playsinline=1rel=0" allow="autoplay" frameBorder="0"></iframe>
              </div>
            </div>
          </section>

          <section className="rich-text -bg-white -with-stepped">
            <nav className="breadcrumb" role="navigation">
              <Link to="/zh/event-experience" title="活动体验"> 
                <i className="fas fa-arrow-left"></i>
                活动体验
              </Link>
            </nav>
            <h2>奶源品质和可追溯性</h2>
            <p>在这个农场中，我们决不使用抗生素生长促进剂或激素，符合欧盟法律要求。我们拥有完整的追溯系统，从小牛出生，到出生后数小时内进行标记，再到提供小牛护照，它确保我们无时无刻都具有完整的追溯能力。这使我们赢得了消费者对我们牧场生产的食品的充分信赖。</p>
          </section>

          <ol className="stepped-navigation">
            <li className="stepped-navigation__item"><Link to="/zh/event-experience/grazing"></Link></li>
            <li className="stepped-navigation__item"><Link to="/zh/event-experience/family"></Link></li>
            <li className="stepped-navigation__item -active"><span>奶源品质</span></li>
          </ol>
        </main>
      </ChineseLayout>
    )
  }
}

export default Farms